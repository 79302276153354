<template>
  <div class="captcha">
    <div class="top">
      <van-nav-bar
        title="忘记密码"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
      />
      <p>验证码已发送至</p>
      <p>+86 {{ $route.query.telephone | formatTelephone }}</p>
    </div>
    <div class="captchaText">
      <!-- 密码输入框 -->
      <van-password-input
        :value="code"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
        :length="4"
        :gutter="10"
        :error-info="errorInfo"
      />
      <p
        style="display: flex"
        class="countDown"
        v-if="time !== 0"
      >
        <van-count-down
          :time="time"
          format="sss"
          @finish="time = 0"
        />
        后重新发送验证码
      </p>
      <p
        style="display: flex"
        class="resendCode"
        v-else
        @click="onClick"
      >
        重新发送验证码
      </p>
      <!-- 数字键盘 -->
      <van-number-keyboard
        v-model="code"
        :show="showKeyboard"
        @blur="showKeyboard = false"
        :maxlength="4"
      />
    </div>
  </div>
</template>

<script>
import { checkPhoneCode, reqGetCode } from '@/api/user'
export default {
  name: 'captcha',
  created() {
    if (!this.$route.query.telephone) {
      this.$router.back()
    }
  },
  data() {
    return {
      code: '',
      showKeyboard: false,
      errorInfo: '',
      time: 60 * 1000
    }
  },
  methods: {
    // 重新发送验证码
    async onClick() {
      try {
        await reqGetCode(this.$route.query.telephone)
        this.time = 60 * 1000
      } catch (error) {
        if (error.response.data.status === 'E00030098') {
          this.$toast.fail({
            message: '余额不足，请联系管理员!',
            duration: 500
          })
          return
        }
        this.$toast.fail({
          message: '获取验证码失败!',
          duration: 500
        })
      }
    }
  },
  watch: {
    async code(code) {
      if (code.length === 4) {
        // this.errorInfo = '验证码密码错误'
        // this.$router.push('/resetPassword')
        try {
          await checkPhoneCode({
            phone: this.$route.query.telephone,
            code: code
          })
          this.$router.push({
            path: '/resetPassword',
            query: {
              telephone: this.$route.query.telephone
            }
          })
        } catch (error) {
          this.errorInfo = '验证码密码错误'
        }
      } else {
        this.errorInfo = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.captcha {
  background-color: #eeeeee;
  position: absolute;
  width: 100%;
  height: 100%;

  ::v-deep {
    .top {

      // .van-icon {
      //   font-size: 30px;
      //   padding-left: 10px;
      // }
      p {
        margin-top: 50px;
        font-size: 20px;
        font-weight: 700;
        text-indent: 1rem;

        &:nth-child(3) {
          margin: 0;
          color: #00a6f0;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    .captchaText {
      margin-top: 50px;

      .countDown {
        font-weight: normal;
        font-size: 14px;
        justify-content: start;
        padding-left: 40px;
        margin-top: 30px;
      }

      .resendCode {
        font-weight: normal;
        font-size: 14px;
        justify-content: start;
        padding-left: 40px;
        text-decoration: underline;
        color: #1990ff;
        margin-top: 30px;
      }
    }
  }
}
</style>
